<template>
  <div class="main">
    <div class="mian-content">
      <div class="backG"></div>
      <div class="contentT">
        <p class="name">啊哦，网站正在维护中...</p>
        <p class="div"></p>
        <p class="desc">给您带来不便请谅解，进入网站请转至此处</p>
        <p class="manage" style="margin-bottom: 40px">
          <Input :readonly="true" class="input" v-model="manageUrl" />
          <Button @click="intoManage(1)">
            <svg aria-hidden="true" class="icon iconStyle">
              <use xlink:href="#icon-jiantou2" />
            </svg>
            <span>进入管理端</span>
          </Button>
        </p>
        <p class="manage">
          <Input :readonly="true" class="input" v-model="userUrl" />
          <Button @click="intoManage(2)">
            <svg aria-hidden="true" class="icon iconStyle">
              <use xlink:href="#icon-jiantou2" />
            </svg>
            <span>进入用户端</span>
          </Button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'maintain',
  data() {
    return {
      manageUrl: '管理端   https://enter.yuantusoft.com',
      userUrl: '用户端   https://learn.yuantusoft.com'
    }
  },
  methods: {
    intoManage(type) {
      if (type === 1) return (window.location.href = 'https://enter.yuantusoft.com')
      else return (window.location.href = 'https://learn.yuantusoft.com')
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  height: 100%;
  overflow: auto;
  padding: 7% 20% 10% 20%;
  .mian-content {
    display: flex;
    height: 100%;
    min-width: 1050px;
    min-height: 450px;
    .backG {
      width: 540px;
      height: 100%;
      background: no-repeat center url('./../assets/notFound/notFound.png');
      background-size: contain;
    }
    .contentT {
      text-align: left;
      color: #333;
      font-size: 16px;
      font-weight: 500;
      flex: auto;
      padding: 140px 55px;
      .name {
        font-size: 36px;
        color: #1f1f1f;
        font-weight: 700;
        margin-bottom: 22px;
      }
      .div {
        width: 40px;
        height: 5px;
        border-radius: 4px;
        background: #e0eaff;
      }
      .desc {
        margin: 61px 0 32px 0;
        color: #888;
        /*text-align: left;*/
      }
      .manage {
        display: flex;
        .input {
          ::v-deep .ivu-input {
            width: 405px;
            height: 46px;
            border-radius: 6px;
            border: 1px solid #9ab4fd;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          border-radius: 6px;
        }
        .iconStyle {
          width: 21px;
          height: 18px;
          margin-right: 8px;
        }
        button {
          margin-left: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          width: 135px;
          height: 44px;
          border-radius: 23px;
          background: #bbd1ff;
          color: #fff;
          box-shadow: 0px 1px 8px 0px rgba(206, 221, 255, 1);
          &:hover {
            border-color: #bbd1ff;
            background: #4579ea;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
